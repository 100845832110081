<template>
  <div battle-academia-second-prize>
    <h4 class="title">2-3위 대학 리워드</h4>
    <p class="sub">대학 랭킹[ 대학 소속 팀 point 합산을 통해 산정 ]</p>
    <div class="prize">
      <div class="prize-item">소환사의 전당 기록</div>
      <div class="prize-item">대학 소속<br>참가자 100명에게<br>황금올리브 치킨 선물!</div>
    </div>
    <p class="detail">소환사의 전당 | 라이엇 공식 아마추어 대회 수상자들의 기록을 남기는 명예의 공간</p>
  </div>
</template>
<script>
export default {
  name: 'BattleAcademiaSecondPrize',

};

</script>
<style lang="less">
@import '~@/less/proj.less';
[battle-academia-second-prize] { .mh-c;
  .title { .wh(240, 40); .bgc(#1e54b7); .br(12); .fs(20, 40px); .mh-c; .tc; .mb(16); .font-g;}
  .sub { .fs(12, 14px); .ls(-1); .o(.8);.tc; .font-g;}
  .prize {
    .prize-item { .wh(303, 190); .br(8); .mh-c;
      &:nth-of-type(1) { .rel; .mt(30);.pt(26); .bgc(#ebebf0); .c(#24262c); .bold; .fs(18, 21.6px);
        &:before { .abs; .lt(105, 63); .cnt; .wh(108, 106); .contain('@{academy}/icon/icon-reward-1.svg'); }
      }
      &:nth-of-type(2) { .rel; .mt(6); .mb(16); .pt(11); .bgc(#3a3c41); .fs(16, 20px); .tc; .font-g;
        &:before { .abs; .lt(72, 79); .cnt; .wh(160); .contain('@{attach}/bbq.svg'); }
      }
    }
  }
  .detail { .flex; .flex-jc; .flex-ai; .max-w(303); .h(32); .mh-c; .o(.5); .fs(12, 16px); font-weight: 400; .tc;}

  @media (@tl-up) {
    .title { .wh(280, 48); .c(white); .fs(24, 48px); }
    .sub { .fs(14, 19.6px); }
    .prize { .flex; flex-direction: row; align-items: center; justify-content: center; .mt(16); .mb(20);
      .prize-item {.wh(256, 276); .m(0);
        &:nth-of-type(1) { .mt(0); .mr(19); .pt(53);
          &:before { .wh(140, 120); .lt(82, 115);}
        }
        &:nth-of-type(2) { .pt(28); .m(0, 0);
          &:before { .wh(200, 126); .lt(30, 115);}
        }
      }
    }
    .detail { .max-w(100%);}
  }
}

</style>
