import { render, staticRenderFns } from "./BattleAcademiaItems.vue?vue&type=template&id=cfa289cc&"
import script from "./BattleAcademiaItems.vue?vue&type=script&lang=js&"
export * from "./BattleAcademiaItems.vue?vue&type=script&lang=js&"
import style0 from "./BattleAcademiaItems.vue?vue&type=style&index=0&id=cfa289cc&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports