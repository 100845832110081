<template>
  <div battle-academia-final-prize>
    <h4 class="title">파이널 토너먼트 리워드</h4>
    <p class="sub">상위 포인트 8개 팀은 최종순위를 결정하는 파이널 토너먼트를 진행합니다.</p>
    <div class="prize">
      <div class="prize-item">
        <div class="ranking">1위</div>
        <em>상금</em> <b>500 만원</b>
      </div>
      <div class="prize-item">
        <div class="ranking">2위</div>
        <em>상금</em> <b>300 만원</b>
      </div>
      <div class="prize-item">
        <div class="ranking">3위</div>
        <em>상금</em> <b>200 만원</b>
      </div>
      <div class="prize-item">
        <div class="ranking">4위</div>
        <em>상금</em> <b>100 만원</b>
      </div>
      <div class="prize-item">
        <div class="ranking">5-8위</div>
        <em>상금</em> <b>50 만원</b>
      </div>
    </div>
    <p class="detail">공통 | 소환사의 전당 기록</p>
  </div>
</template>
<script>
export default {
  name: 'BattleAcademiaFinalPrize',
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[battle-academia-final-prize] {
  .title { .wh(240, 40); .mb(16); .mh-c; .fs(20, 40px); .c(#000); .bgc(#14d3a5); .br(12); }
  .sub { .max-w(221); .mh-c; .fs(12, 14px); .medium; .font-g; .c(white); .o(.8); }
  .prize { .m(15, 0, 25);
    .prize-item { .rel; .max-w(532); .w(303); .mh-c; .mb(8); .pl(173); .tl; .bgc(white); .no-repeat; .br(8); .crop;
      &:nth-of-type(1) { .h(78); .pt(14); .pb(16); .bg('/img/event/academy/icon/icon-prize-3.svg'); .bg-s(112, 44); .bg-xy(45.5, 17);
        > em {
          &::after { content: ' + 트로피'; .bold; }
        }
      }
      &:nth-of-type(2) { .h(78); .pt(14); .pb(16); .bg('/img/event/academy/icon/icon-prize-2.svg'); .bg-w(69); .bg-xy(69, 13); }
      &:nth-of-type(3) { .h(78); .pt(14); .pb(16); .bg('/img/event/academy/icon/icon-prize-1.svg'); .bg-w(69); .bg-xy(69, 20); }
      &:nth-of-type(4) { .h(60); .pt(6); .pb(6); .bg('/img/event/academy/icon/coin_twinkle.svg'); .bg-w(73); .bg-xy(69, 11); }
      &:nth-of-type(5) { .h(60); .mb(0); .pt(6); .pb(6); .bg('/img/event/academy/icon/coin_twinkle.svg'); .bg-w(73); .bg-xy(69, 11); }
      &:last-child { .mb(0); }
      .ranking { .abs; .lt(0, 0); z-index: 1; .wh(54, 28); .fs(16, 28); .medium; .ls(-1); .font-g; .tc; .bgc(#000); border-bottom-right-radius: 5px;

      }
      > em { .block; .fs(14, 16); .medium; .c(#363840); .font-g; }
      > b { .block; .mt(4); .fs(26, 28); .c(#df3a3a); .bold; .ls(-1); .font-g; }
    }
  }
  .detail { .flex; .flex-jc; .flex-ai; .max-w(303); .h(32); .mh-c; .o(.5); .fs(12, 16px); .regular; .tc;}

  @media (@tl-up) {
    .title { .wh(280, 48); .fs(24, 48); }
    .sub { .max-w(100%); .fs(14, 19.6); .c(white); .ls(-1); .o(.8);}
    .prize { .w(532); .mh-c; .mt(16); .mb(20);
      .prize-item { .rel; .ib; .max-w(532); .wh(260, 78); .mh-c; .mb(8); .pt(14); .pb(16); .pl(141); .tl; .bgc(white); .no-repeat; .br(8); .crop;
        &:nth-of-type(1) { .wh(100%, 96); .pl(266); .bg-w(148); .bg-xy(90, 19);
          &::after { content: ' + 트로피'; .block; .mt(-5); .fs(20, 26); .c(#000); .bold; .font-g; }
          > em { .ib; .fs(24, 47); .mr(12); vertical-align: top;
            &::after { .hide; }
          }

          > b { .ib; .fs(40, 48); }

        }
        &:nth-of-type(2) { .h(78); .mr(12); .bg-w(69); .bg-xy(57, 13); }
        &:nth-of-type(3) { .h(78); .bg-w(69); .bg-xy(57, 20); }
        &:nth-of-type(4) { .h(78); .mr(12); .pt(14); .pb(16); .mb(0); .bg-s(73, 40); .bg-xy(57, 19); }
        &:nth-of-type(5) { .h(78); .mb(0); .pt(14); .pb(16); .bg-s(73, 40); .bg-xy(57, 19); }
        &:last-child { .mb(0); }
        .ranking { .abs; .lt(0, 0); z-index: 1; .wh(54, 28); .fs(16, 28); .medium; .ls(-1); .font-g; .tc; .bgc(#000);

        }
        > b { .block; .mt(4); .fs(26, 28); .c(#df3a3a); .bold; .ls(-1); .font-g; }
      }
    }
    .detail { .max-w(100%); }
  }
}
</style>
