<template>
  <div battle-academia-first-prize>
    <h4 class="title">1위 대학 리워드</h4>
    <p class="sub">대학 랭킹 [ 대학 소속 팀 point 합산을 통해 산정 ]</p>
    <div class="prize">
      <div class="main-prize"><em>장학금</em><b>1,000만원</b></div>
      <img class="plus" src="/img/event/academy/icon/white_plus.svg" />
      <div class="sub-prize">
        <div class="prize-item">배틀 아카데미아<br>우승 깃발</div>
        <div class="prize-item">소환사의 전당 기록</div>
        <div class="prize-item">황금올리브 치킨<br>100마리 증정</div>
      </div>
    </div>
    <p class="detail">소환사의 전당 | 라이엇 공식 아마추어 대회 수상자들의 기록을 남기는 명예의 공간</p>
  </div>
</template>
<script>
export default {
  name: 'BattleAcademiaFirstPrize',

};

</script>
<style lang="less">
@import '~@/less/proj.less';
[battle-academia-first-prize] { .tc;
  .title { .wh(240, 40); .bgc(#1e54b7); .c(white); .br(12); .fs(20, 40px); .mb(16); .mh-c;}
  .sub { .fs(12, 14px); .medium; .font-g; .c(white); .o(.8);}
  .prize { max-width: 295px; .mh-c;
    .main-prize { .mh-c;.mt(27); .c(black); .br(8); .bgc(#ebebf0); .wh(295, 78); .p(15, 0); .font-g;
      > em { .ib; .mr(8); .fs(20, 18); .c(#363840); vertical-align: text-bottom; }
      > b { .fs(36, 48); .c(#df3a3a); .bold; }
    }
    .plus { .m(20, 0);}
    .sub-prize { .flex; flex-direction: row; flex-wrap: wrap; align-items: center; justify-content: center;
      .prize-item {.wh(140); .fs(14, 16.8); .c(#24262c); .bold; .bgc(#ebebf0); .br(8);
        &:nth-of-type(1) { .mr(12); .rel; .pt(91); .pl(5);
          &:before { .cnt; .abs; .wh(60); .contain('@{academy}/icon/icon_flag.svg'); .lt(45, 25);}
        }
        &:nth-of-type(2) { .rel; .pt(98); .pl(3);
          &:before {.cnt; .abs; .wh(55); .contain('@{academy}/icon/icon-reward-1.svg'); .lt(49, 32);}
        }
        &:nth-of-type(3) { .bgc(#3a3c41); .c(white); .medium; .wh(100%, 101); .mt(12); .mb(19); .rel; .pt(33); .pl(120);
          &:before { .cnt; .abs; .wh(96, 60); .contain('@{attach}/bbq.svg'); .lt(27, 22);}
        }
      }
    }
  }
  .detail { .flex; .flex-jc; .flex-ai; .max-w(303); .h(32); .mh-c; .o(.5); .fs(12, 16px); .regular; .tc;}

  @media (@tl-up) {
    .title { .wh(280, 48); .fs(24, 48); }
    .sub { .fs(14, 19.6); .c(white); .ls(-1); .o(.8);}
    .prize { max-width: 532px; .mh-c;
      .main-prize { .wh(532, 96); .p(23, 0); .fs(32, 32); }
      .sub-prize { .mb(20);
        .prize-item { .wh(121); .fs(14);
          &:nth-of-type(1) { .pt(75); .mr(16);
            &:before { .lt(40, 10);}
          }
          &:nth-of-type(2) { .mr(16); .pt(85);
            &:before {.lt(42, 15);}
          }
          &:nth-of-type(3) { .wh(121); .mt(0); .mb(0); .pt(77); .pl(2);
            &:before { .lt(16, 14); .wh(79, 60); }
          }
        }
      }
    }
    .detail { .max-w(100%); }
  }
}
</style>
