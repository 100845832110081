<template>
  <div popup>
    <p class="dim" />
    <div class="popup-holder" :class="{sticky}">
      <custom-scroll ref="scroll">
        <div class="inside" v-click-outside="() => $emit('clickOutside')">
          <slot />
        </div>
      </custom-scroll>
    </div>
  </div>
</template>

<script>
import CustomScroll from '@/views/components/common/CustomScroll.vue';
// import {SCROLL_FREEZE} from '@/store/modules/organizer';

export default {
  name: 'Popup',
  components: { CustomScroll },
  props: {},
  data() {
    return {
      target: null,
      holder: null,
      sticky: false,
      scrollY: 0,
    };
  },
  watch: {
    $route() {
      this.$emit('close');
    },
  },
  computed: {},
  methods: {
    test() {
      this.$emit('close');
    },
    resize() {
      this.sticky = this.holder.clientHeight - this.target.clientHeight < 60;
    },
    positionY(v, t = 300) {
      this.$refs.scroll.positionY(v, t);
    },
  },
  mounted() {
    this.holder = this.$el.querySelector('.popup-holder');
    this.target = this.$el.querySelector('.inside');
    if (this.target) {
      window.addEventListener('resize', this.resize);
      this.resize();
    }
    this.$scroll.freeze();

    let target = this.target.querySelector('input');
    target = target || this.target.querySelector('button');
    if (target) target.focus();
  },
  beforeDestroy() {
    this.$scroll.release();
    window.removeEventListener('resize', this.resize);
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[popup] {
  .inside > * {.mh-c;}
}
</style>
