<template>
  <div battle-academia-items>
    <OfficialLeagueGameInfoTemplate :title="title" :slides="slides" @more-click="leagueRewordModal" />
  </div>
</template>

<script>
import OfficialLeagueGameInfoTemplate from '@/views/components/gameHome/OfficialLeagueGameInfoTemplate.vue';
import BattleAcademiaFirstPrize from '@/views/components/gameHome/lol/gameInfo/slides/BattleAcademiaFirstPrize.vue';
import BattleAcademiaSecondPrize from '@/views/components/gameHome/lol/gameInfo/slides/BattleAcademiaSecondPrize.vue';
import BattleAcademiaFinalPrize from '@/views/components/gameHome/lol/gameInfo/slides/BattleAcademiaFinalPrize.vue';
import BattleAcademiaPointPrize from '@/views/components/gameHome/lol/gameInfo/slides/BattleAcademiaPointPrize.vue';
import LeagueRewordModal from '@/views/components/gameHome/lol/LeagueRewordModal.vue';

export default {
  name: 'BattleAcademiaItems',
  components: { OfficialLeagueGameInfoTemplate },
  data() {
    return {
      title: {
        title: 'ITEMS',
        titleKo: '리그 특전',
        subTitle:
          '리그에 도전한 누구든지 아이템을 받을 수 있습니다. <br/>대학 점수를 높여 ‘배틀 아카데미아’의 깃발과 특별한 상금을 선점하세요!',
        more: true,
      },
      slides: [BattleAcademiaFirstPrize, BattleAcademiaSecondPrize, BattleAcademiaFinalPrize, BattleAcademiaPointPrize],
    };
  },
  methods: {
    leagueRewordModal() {
      this.$modal(LeagueRewordModal);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[battle-academia-items] {
  @media (@tl-up) {
    [item-reword-slider] { .mt(28); }
  }
  @media (@ds-up) {
    [item-reword-slider] { .mb(0); }
  }
}
</style>
