<template>
  <div battle-academia-point-prize>
    <h4 class="title">개인 포인트 별 보상</h4>
    <p class="sub">대회 참가만 해도 수수께끼 스킨 증정</p>
    <div class="prize">
      <div class="con">
        <div>
          <img src="/img/event/academy/asset/reword-img1.png" alt="reword-image" />
          <span>리그 특전</span>
          <p>
            대회 참가만 해도<br /> <b>수수께끼 스킨</b> 증정!
          </p>
        </div>
        <div>
          <img src="/img/event/academy/asset/reword-img2.png" alt="reword-image" />
          <span>대회 포인트 달성 시</span>
          <p>
            누구든지 15 point 달성 시,<br /> <b>프리미엄 수수께끼 스킨</b> 증정!
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BattleAcademiaPointPrize',
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[battle-academia-point-prize] {
  .title { .wh(240, 40); .mb(16); .mh-c; .fs(20, 40px); .c(white); .bgc(#df3a3a); .br(12); }
  .sub { .max-w(221); .mh-c; .fs(12, 14px); .medium; .font-g; .c(white); .o(.8); }

  .prize { .p(0, 14);
    .con { .max-w(532); .min-h(389); .mh-c; .mt(27); .p(94, 8.75); .bgc(#3a3c41); .br(8);
      > div { .rel; .max-w(303); .mh-c; .pl(90); .tl;
        &:first-child { .mb(24); }
        > img { .abs; .lt(0, 0); z-index: 1; .w(80); }
        > span { .ib; .h(28); .mb(8); .p(0, 8); .fs(14, 28); .ls(-1); .bold; .c(white); .font-g; .bgc(#000); .br(4); }
        > p { .fs(16, 20); .ls(-1); .medium; .c(white); .font-g; }
      }
    }
  }

  .detail { .flex; .flex-jc; .flex-ai; .max-w(303); .h(32); .mh-c; .o(.5); .fs(12, 16px); .regular; .tc;}

  @media (@tl-up) {
    .title { .wh(280, 48); .fs(24, 48); }
    .sub { .max-w(100%); .fs(14, 19.6); .c(white); .ls(-1); .o(.8);}

    .prize {
      .con { .min-h(0); .mt(16); .p(26, 87);
        > div { .max-w(100%); .min-h(100); .pl(118);
          > img { .w(100); }
          > span { .h(32); .mb(14); .p(0, 9); .fs(16, 32); }
          > p { .fs(20, 26); }
        }
      }
    }

    .detail { .max-w(100%); }
  }
}
</style>
