<template>
  <div league-modal-wrap class="league-modal-wrap">
    <popup class="league-modal-popup" @clickOutside="$emit('close')">
      <div class="contents">
        <div class="hd">
          <h2>리그특전</h2>
          <button class="close-btn" @click="$emit('close')">
            <img src="/img/pages/icon/x_button-w.svg" alt="close" />
          </button>
        </div>
        <div class="bd">
          <div class="grid grid-left">
            <div class="box top">
              <h6 class="box-title blue">1위 대학 리워드</h6>
              <div class="reword-box">
                <span>장학금</span>
                <b>1000 만원</b>
              </div>
              <div class="plus">
                <img src="/img/pages/icon/ico-plus-w.svg" alt="plus" />
              </div>
              <div class="between-box-con bb-1">
                <div>
                  <img src="/img/event/academy/icon/icon_flag.svg" alt="reward" />
                  <div class="text">배틀 아카데미아<br />우승 깃발</div>
                </div>
                <div>
                  <img src="/img/event/academy/icon/icon-reward-1.svg" alt="reward" />
                  <div class="text">
                    소환사의 전당 기록
                  </div>
                </div>
              </div>
              <div class="chk-box">
                <img src="/img/pages/attach/bbq.svg" alt="chicken" />
                황금올리브 치킨<br /> 100마리 증정
              </div>
            </div>
            <div class="box bottom">
              <h6 class="box-title blue">2-3위 대학 리워드</h6>

              <div class="between-box-con bb-1">
                <div>
                  <img src="/img/event/academy/icon/icon-reward-1.svg" alt="reward" />
                  <div class="text">
                    소환사의 전당 기록
                  </div>
                </div>
                <div class="chk-box2">
                  <img src="/img/pages/attach/bbq.svg" alt="chicken" />
                  <div class="text">
                    황금올리브 치킨<br /> 100마리 증정
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid grid-right">
            <div class="box">
              <h6 class="box-title green">파이널 토너먼트 리워드</h6>
              <div class="reword-box with-label">
                <em>1위</em>
                <span>상금</span>
                <b>500 만원</b>
              </div>
              <div class="plus">
                <img src="/img/pages/icon/ico-plus-w.svg" alt="plus" />
              </div>
              <div class="between-box-con bb-1">
                <div>
                  <img src="/img/event/academy/icon/icon-prize-5.svg" alt="reward" />
                  <div class="text">
                    우승 트로피
                  </div>
                </div>
                <div>
                  <img src="/img/event/academy/icon/icon-reward-1.svg" alt="reward" />
                  <div class="text">
                    소환사의 전당 기록
                  </div>
                </div>
              </div>
              <div class="rank-reword">
                <div class="between-box-con bb-1">
                  <div>
                    <em>2위</em>
                    <img src="/img/event/academy/icon/icon-prize-2.svg" alt="reward" />
                    <div class="text">
                      <span>상금</span>
                      <b>300 만원</b>
                    </div>
                  </div>
                  <div>
                    <em>3위</em>
                    <img src="/img/event/academy/icon/icon-prize-1.svg" alt="reward" />
                    <div class="text">
                      <span>상금</span>
                      <b>200 만원</b>
                    </div>
                  </div>
                  <div class="coin">
                    <em>4위</em>
                    <img src="/img/event/academy/asset/twincle-coin.svg" alt="reward" />
                    <div class="text">
                      <span>상금</span>
                      <b>100 만원</b>
                    </div>
                  </div>
                  <div class="coin">
                    <em>5-8위</em>
                    <img src="/img/event/academy/asset/twincle-coin.svg" alt="reward" />
                    <div class="text">
                      <span>상금</span>
                      <b>50 만원</b>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sub-info">
                <span>공통</span>
                소환사의 전당 기록
              </div>
            </div>
          </div>
          <div class="grid grid-bottom">
            <div class="box">
              <h6 class="box-title red">개인 포인트 별 보상</h6>
              <div class="reword-box2">
                <div>
                  <img src="/img/event/academy/asset/reword-img1.png" alt="reword-image" />
                  <span>리그 특전</span>
                  <p>
                    대회 참가만 해도<br /> <b>수수께끼 스킨</b> 증정!
                  </p>
                </div>
                <div>
                  <img src="/img/event/academy/asset/reword-img2.png" alt="reword-image" />
                  <span>대회 포인트 달성 시</span>
                  <p>
                    누구든지 15 point 달성 시,<br /> <b>프리미엄 수수께끼 스킨</b> 증정!
                  </p>
                </div>
              </div>
            </div>
            <div v-show="false" class="box">
              <p>상금 상세 설명</p>
              <span>지금 리그에 도전하여 대학 순위를 높여보세요!</span>
            </div>
            <div class="detail-intro">
              <p>상금 상세 설명</p>
              <ul>
                <li>1위 대학 장학금 및 우승 깃발 | 장학금 1000만원과 우승 깃발은 1위 대학 장학처에 기부됩니다</li>
                <li>
                  1~3위 대학 치킨 보상 | 대회 참가 횟수에 따라서 각 대학 별 상위 100 인에게 쿠폰 형태로 지급됩니다. (1인 1개 치킨 쿠폰)
                </li>
                <li>
                  상위 8개 팀 리워드 | 파이널 토너먼트 종료 후 순위에 따라 각 팀 팀장에게 연락 후 지급될 예정입니다.
                </li>
                <li>개인 포인트 별 리워드 | 경기일 기준 약 2주 내에 참여한 소환사 계정으로 자동 지급될 예정입니다.</li>
                <li>소환사의 전당 기록 | 파이널 토너먼트 종료 후 기록실 탭에 추후 기록될 예정입니다.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
import Popup from '@/views/components/common/Popup.vue';

export default {
  name: 'LeagueRewordModal',
  components: { Popup },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[popup] .popup-holder .inside { .max-w(660); .w(100%); }
[league-modal-wrap] { .fix; .lt(0, 0); .h(100%); .bgc(rgba(0, 0, 0, 0.6));

  .contents { .max-w(660); .w(100%); .p(20); .-a(rgba(255, 255, 255, 0.1)); .br(20); .bgc(#222);
    .hd { .rel;
      h2 { .m(8, 0); .fs(28); .c(rgba(255, 255, 255, 0.8)); .tc; .ls(-1); .-a; }
      > button.close-btn { .abs; .rt(0, -10); .z(1); }
    }

    .bd { .flex; justify-content: space-between; flex-wrap: wrap;
      .grid {}
      .grid- {
        &left { .w(300); }

        &right { .w(300);
          .rank-reword { .mt(18); .pt(18); .-t(rgba(255, 255, 255, 0.1));
            .between-box-con {
              > div { .rel; .pt(30); .mb(12);
                &.coin { .pt(35); }
                img { .max-h(33); }
                > em { .abs; .lt(0, 0); .z(1); .font-g; .fs(14); .bold; .c(#fff); .ls(-1); .bgc(#000); .w(54); .p(5, 0); .br-t(8); }

                .text { .mt(4);
                  > span { .mr(4); .fs(12); .c(#24262c); }
                  > b { .font-g; .fs(14); .extra-bold; .c(#e04545); .ls(-1); }
                }
              }
            }
          }

          .sub-info { .m(14, 0, 4, 0); .fs(13); .c(rgba(255, 255, 255, 0.5)); .tc;
            > span { .medium;
              &::after { .cnt; .ib; .wh(1, 10); .m(0, 7); .bgc(rgba(255, 255, 255, 0.5)); }
            }
          }
        }

        &bottom { .w(100%);
          .reword-box2 { .crop; .font-g; .ls(-0.5);
            > div { .rel; .fl; .w(50%); .pl(92); .h(80);
              > img { .abs; .lt(0, 0); .z(1); }
              > span { .ib; .mt(3); .mb(8); .p(4.5, 10); .br(4); .fs(12); .c(#fff); .bold; .bgc(#000); }
              > p { .fs(14); .light; .c(#fff); .lh(20); }
            }
          }

          .box {
            &:nth-child(2) { .tc;
              > p { .fs(13); .c(rgba(255, 255, 255, 0.7)); }
              > span { .fs(9); .c(rgba(255, 255, 255, 0.3)); }
            }
          }

          .detail-intro { .mt(29);
            > p { .mb(14); .fs(12); .medium; .c(rgba(255, 255, 255, 0.7)); }

            > ul { .pl(0); .ml(16);
              > li { list-style: disc; .mb(5); .lh(13); .fs(11); .c(rgba(255, 255, 255, 0.3)); }
            }
          }
        }
      }

      .box { .p(32, 32, 20, 32); .mt(29); .bgc(rgba(255, 255, 255, 0.1)); .br(12);
        h6.box-title { .w(156); .mh-c; .mb(-30); .p(6.5, 0); .fs(16); .c(#fff); .ls(-1); .tc; .bgc(#1e54b7); .br(7.25); transform: translateY(-48px);
          &.green { .c(#191919); .bgc(#14d3a5); }
          &.red { .c(#fff); .bgc(#df3a3a); }
        }

        .reword-box { .rel; .crop; .font-g; .p(12.5, 0); .bgc(#ebebf0); .br(8); .tc;
          &.with-label { .pl(30);
            > em { .abs; .lt(0, 0); .z(1); .h(100%); .p(20, 8); .fs(14); .c(#fff); .bold; .ls(0.5); .bgc(#000); }
          }
          > span { .fs(18); .medium; .ls(-0.5); }
          > b { .ml(7); .fs(28); .extra-bold; .c(#df3a3a); .ls(-1); }
        }

        .plus { .m(16, 0); .tc; }

        .between-box-con { .flex; justify-content: space-between; flex-wrap: wrap;
          > div { .wh(112); .p(17, 0, 13, 0); .bgc(#ebebf0); .br(8); .tc;
            .text { .wh(100%, 35); .flex-center; .fs(12); .extra-bold; .ellipsis(2); }
            img { .block; .max-h(48); .mh-c; }
          }

          &.bb-1 {
            > div {
              &:nth-child(2) {
                img { .mr(26); }
              }
            }
          }
        }

        .chk-box { .h(112); .p(8, 0); .mt(12); .fs(12); .medium; .c(#fff); .ls(-1); .tc; .bgc(rgba(255, 255, 255, 0.1)); .br(8);
          > img { .block; .w(96); .mh-c; .mb(8); }
        }

        &.bottom { .p(37, 32, 21, 32);
          .chk-box2 { .p(10, 10, 13, 10); .fs(12); .tc; .bgc(rgba(255, 255, 255, 0.1));
            .text { .medium; .c(#fff); }

            > img { .block; .w(96); .mh-c; .m(5, 0); .mr(7) !important; }
          }

          .between-box-con > div:nth-child(1) {

            img { .mr(26); }
          }
        }
      }
    }
  }

  @media (@tp-down) {
    [popup] .popup-holder .inside { .max-w(340); .w(95%); }
    .contents { .max-w(340); .w(100%); .p(20, 5%);
      .h2 {
        > h2 { .fs(26); }
      }
      .bd {
        .grid { .w(100%);
          .box { .p(0, 5%);
            .between-box-con { justify-content: space-around; }
          }
        }

        .grid-bottom .reword-box2 > div { .w(100%); .mb(20);
          > p { .fs(12); }
        }
      }
    }
  }
}
</style>
